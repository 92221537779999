import React, { Component, Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ImageRow from "../ImageRow";
import developmentSecurityIcon from '../../images/development-security-icon.svg';
import dataStorageIcon from '../../images/data-storage-security-icon.svg';
import authenticationIcon from '../../images/authentication-security-icon.svg';
import encryptionIcon from '../../images/encryption-security-icon.svg';
import accessIcon from '../../images/access-control-security-icon.svg';
import backupsIcon from '../../images/backups-security-icon.svg';
import paymentsIcon from '../../images/payment-security-icon.svg';
import styles from "./security-content.module.scss";

class SecurityContent extends Component {
  securitySections = [
    {
      title: 'Data hosting and storage',
      content: 'Our servers are hosted in DigitalOcean and Amazon Web Services (AWS) facilities which are SSAE16 SOC-2 and SOC-3 compliant.',
      image: dataStorageIcon,
    },
    {
      title: 'Authentication',
      content: 'Each user in Entracker has a unique, password-protected account with a verified email address. The password is stored securely using a strong hashing algorithm that is uniquely salted.',
      image: authenticationIcon,
    },
    {
      title: 'Encryption',
      content: 'Communications between you and Entracker servers are encrypted via industry best-practices HTTPS and Transport Layer Security (TLS) by default.',
      image: encryptionIcon,
    },
    {
      title: 'Access control',
      content: 'Role-based access control ensures users can only use data and application features that are related to their responsibilities.',
      image: accessIcon,
    },
    {
      title: 'Backups',
      content: 'Our databases are backed up every 10 minutes. All backup data is encrypted and stored securely in multiple locations.',
      image: backupsIcon,
    },
    {
      title: 'Payments',
      content: 'Paddle is our Merchant of Record and are handling subscription payments and invoicing on our behalf. Transactions handled by them are all PCI compliant.',
      image: paymentsIcon,
    },
    {
      title: 'Development security',
      content: 'Every code change goes throught detailed testing procedure. Application is first tested manuly on development server, also our suit of automatic tests checks the most vital application functionalities. Before production release our team makes final detailed tests.',
      image: developmentSecurityIcon,
    },
  ];

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            securityIcon1: file(relativePath: { eq: "project-reports.png" }) {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
          }
        `}
        render={data => (
          <Fragment>
            {this.securitySections.map((item, key) =>
            <div key={`security-${key}`} className={styles.sectionWrap}>
              <ImageRow
                title={item.title}
                content={item.content}
                isImgLeft={key % 2 === 0}
                isGrayBg={key % 2 === 1}
                image={item.image}
                alt={item.title}
              />
            </div>)}
          </Fragment>
        )}
      />
    );
  }
}

export default SecurityContent;
